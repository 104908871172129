import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  agreement,
  personalInfo,
  location,
  credit,
  push,
} from "../../utils/policyData";
import { backBtn } from "../../assets/images";

import { media } from "../../utils/breakPoints";

const Main = styled.div`
  height: 100vh;
  background-color: white;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: ${(props) => props.boxShadow};
`;

const VerticalBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const HorizonBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : 0)};
  padding: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "15px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  margin-top: ${(props) => `${props.marginTop}px`};
`;

const Link = styled.a`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "15px")};
  color: ${(props) => (props.color ? props.color : "black")};
  cursor: pointer;
  ${media.greaterThan("tablet")`
    .breakpoint{
      display:none;
      line-height:30px;
    }
  `}
`;

const Bar = styled.div`
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #aaaaaa;
  margin: 0 5px;
  ${media.lessThan("tablet")`
  margin: 0 10px;
  `}
`;

const Container = styled.div`
  white-space: pre-wrap;
  padding: 130px 150px 10px;
  background-color: white;
  color: #4a4a4a;
  ${media.between("tablet", "desktop")`
    padding: 130px 100px;    
  `}
  ${media.lessThan("tablet")`
    padding: 130px 50px;  
  `}
`;

const BackBtn = styled.img`
  position: absolute;
  left: 0;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const PolicyPresenter = ({ match }) => {
  const {
    params: { kinds },
  } = match;

  const [title, setTitle] = useState();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (kinds === "agreement") {
      setTitle("서비스 이용약관");
    } else if (kinds === "personal-info") {
      setTitle("개인정보 취급방침");
    } else if (kinds === "location") {
      setTitle("위치정보 이용약관");
    }
    // else if (kinds === "push") {
    //   setTitle("서비스 알림(광고성)동의");
    // } else if (kinds === "credit") {
    //   setTitle("신용카드 간편결제");
    // }
  }, [kinds]);

  const handleScroll = () => {
    if (window.scrollY < 5) {
      return setIsScrolled(false);
    } else {
      return setIsScrolled(true);
    }
  };

  window.addEventListener("scroll", handleScroll);

  return (
    <Main>
      <Header
        boxShadow={isScrolled ? "0px 5px 5px 1px rgba(0, 0, 0, 0.1)" : ""}
      >
        <VerticalBox>
          <HorizonBox>
            <BackBtn
              onClick={() => (window.location.href = "/")}
              src={backBtn}
            />
            <Title fontSize={20} fontWeight={300}>
              {title}
            </Title>
          </HorizonBox>
          <HorizonBox marginTop={10}>
            <Link
              href="/policy/agreement"
              fontSize={13}
              color={kinds === "agreement" ? "#608cf1" : "#aaaaaa"}
            >
              서비스 이용약관
            </Link>
            <Bar />
            <Link
              href="/policy/personal-info"
              fontSize={13}
              color={kinds === "personal-info" ? "#608cf1" : "#aaaaaa"}
            >
              개인정보 취급방침
            </Link>
            <Bar />
            <Link
              href="/policy/location"
              fontSize={13}
              color={kinds === "location" ? "#608cf1" : "#aaaaaa"}
            >
              위치정보 이용약관
            </Link>
            {/* <Bar />
            <Link
              href="/policy/push"
              fontSize={13}
              color={kinds === "push" ? "#608cf1" : "#aaaaaa"}
            >
              서비스
              <br className="breakpoint" />
              알림동의
            </Link>
            <Bar />
            <Link
              href="/policy/credit"
              fontSize={13}
              color={kinds === "credit" ? "#608cf1" : "#aaaaaa"}
            >
              신용카드
              <br className="breakpoint" />
              간편결제
            </Link> */}
          </HorizonBox>
        </VerticalBox>
      </Header>
      <Container>
        {kinds === "agreement" ? agreement : null}
        {kinds === "personal-info" ? personalInfo : null}
        {kinds === "location" ? location : null}
        {kinds === "push" ? push : null}
        {kinds === "credit" ? credit : null}
      </Container>
    </Main>
  );
};

export default PolicyPresenter;
