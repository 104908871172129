import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

import { media } from "../../utils/breakPoints";
import Footer from "../../Components/Footer";
import Pager from "../../Components/Pager";
import ReactPageScroller from "../../scroller";
import { AppStore, GooglePlay, Logo } from "../../assets/images";

//팝업 관련 import
// import moment from "moment";
// import PopUpView from "../../Components/PopUpView";
// import PopUpDC from "../../PopUpDC";

import FirstComponent from "./innerPage/FirstComponent";
import SecondComponent from "./innerPage/SecondComponent";
import ThirdComponent from "./innerPage/ThirdComponent";
import FourthComponent from "./innerPage/FourthComponent";
import FifthComponent from "./innerPage/FifthComponent";
import SixthComponent from "./innerPage/SixthComponent";
import SeventhComponent from "./innerPage/SeventhComponent";

class FullPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentPage: null,
      cookies: props.cookies,
      showPopUp: false,
      widthLong: false,
      widthLimit: false,
    };
  }

  componentDidMount() {
    // const { cookies } = this.props;

    // if (cookies) {
    //   const currentCookies = cookies.get(PopUpDC.COOKIE_VALUE);
    //   this.setState({
    //     showPopUp: !currentCookies,
    //   });
    // }

    window.addEventListener("load", () => {
      this.handleResize();
    });

    window.addEventListener("resize", () => {
      this.handleResize();
    });
  }

  handleResize = () => {
    if (window.innerWidth >= window.innerHeight) {
      this.setState({ widthLong: true });
    } else {
      this.setState({ widthLong: false });
    }
    if (window.innerWidth < 813) {
      this.setState({ widthLimit: true });
    } else {
      this.setState({ widthLimit: false });
    }
  };

  //팝업 닫기 함수
  // closePopUp = (selCheck) => {
  //   const { cookies } = this.props;
  //   if (cookies) {
  //     if (selCheck) {
  //       const expires = moment().add(1, "days").toDate();
  //       cookies.set(PopUpDC.COOKIE_VALUE, true, { path: "/", expires });
  //     }
  //   }
  //   this.setState({ showPopUp: false });
  // };

  handlePageChange = (number) => {
    this.setState({ currentPage: number });
  };

  render() {
    //애플, 구글 스토어 링크
    const BtnClick = (type) => {
      if (type === "android") {
        window.open(
          "https://play.google.com/store/apps/details?id=com.lawdians.app.miapet"
        );
      } else {
        window.open(
          "https://apps.apple.com/kr/app/%EB%AF%B8%EC%95%84%ED%8E%AB/id1513271618"
        );
      }
    };

    return (
      <React.Fragment>
        <Helmet>
          <title>유기동물 없는 행복한 세상 미아펫</title>
        </Helmet>
        {/* {this.state.showPopUp && this.state.cookies ? (
          <>
            <Overlay />
            <PopUpView closePopUp={this.closePopUp} />
          </>
        ) : null} */}
        <Pager currentPage={this.state.currentPage} />
        <MainContainer>
          <LogoImageBox onClick={() => (window.location.href = "/")}>
            <Image src={Logo} alt="로고이미지" />
          </LogoImageBox>
          <StoreContainer>
            <StoreImageBox onClick={() => BtnClick("ios")}>
              <Image src={AppStore} alt="앱스토어버튼" />
            </StoreImageBox>
            <StoreImageBox onClick={() => BtnClick("android")}>
              <Image src={GooglePlay} alt="구글플레이버튼" />
            </StoreImageBox>
          </StoreContainer>

          <ReactPageScroller
            pageOnChange={this.handlePageChange}
            customPageNumber={this.state.currentPage}
          >
            <FirstComponent
              currentPage={this.state.currentPage}
              widthLong={this.state.widthLong}
              widthLimit={this.state.widthLimit}
            />
            <SecondComponent
              currentPage={this.state.currentPage}
              widthLong={this.state.widthLong}
              widthLimit={this.state.widthLimit}
            />
            <ThirdComponent
              currentPage={this.state.currentPage}
              widthLong={this.state.widthLong}
              widthLimit={this.state.widthLimit}
            />
            <FourthComponent
              currentPage={this.state.currentPage}
              widthLong={this.state.widthLong}
              widthLimit={this.state.widthLimit}
            />
            <FifthComponent
              currentPage={this.state.currentPage}
              widthLong={this.state.widthLong}
              widthLimit={this.state.widthLimit}
            />
            <SixthComponent
              currentPage={this.state.currentPage}
              widthLong={this.state.widthLong}
              widthLimit={this.state.widthLimit}
            />
            <SeventhComponent
              currentPage={this.state.currentPage}
              widthLong={this.state.widthLong}
              widthLimit={this.state.widthLimit}
            />
          </ReactPageScroller>

          {this.state.currentPage >= 6 ? (
            <FooterContainer>
              <Footer />
            </FooterContainer>
          ) : null}
        </MainContainer>
      </React.Fragment>
    );
  }
}

export default withCookies(FullPage);

const MainContainer = styled.div`
  position: fixed;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* 파이어폭스*/
`;

const FooterContainer = styled.div`
  width: 100vw;
  height: 500px;
`;

const LogoImageBox = styled.div`
  cursor: pointer;
  position: fixed;
  overflow: hidden;
  width: 170px;
  top: 30px;
  left: 60px;
  z-index: 1;
  margin: 0 7px;
  ${media.between("mobile", "desktop")`
    width:110px;
  `}
  ${media.lessThan("mobile")`
    width:90px;
    left:30px;
  `}
`;

const StoreContainer = styled.div`
  position: fixed;
  width: 160px;
  right: 40px;
  top: 30px;
  z-index: 1;
  ${media.between("mobile", "desktop")`
    width:110px;
  `}
  ${media.lessThan("mobile")`
    width:90px;
    right:20px;
  `}
`;

const StoreImageBox = styled.div`
  cursor: pointer;
  overflow: hidden;
  width: 60px;
  z-index: 2;
  margin: 0 7px;
  display: inline-block;
  ${media.between("mobile", "desktop")`
    width:35px;
  `}
  ${media.lessThan("mobile")`
    width:30px;
  `}
`;

const Image = styled.img`
  display: block;
  width: 100%;
`;

//팝업 등장시 백그라운드
// const Overlay = styled.div`
//   z-index: 1000;
//   position: absolute;
//   height: 100vh;
//   width: 100vw;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.2);
// `;
