import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Home from "../Routes/Home";
import Policy from "../Routes/Policy";
import Information from "../Routes/Information";
// import Naver from "../Routes/Naver";

export default () => (
  <Router>
    <>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/information/:kinds" exact component={Information} />
        <Route path="/information" component={Information} />
        <Route path="/policy/:kinds" exact component={Policy} />
        <Route path="/policy" component={Policy} />
        <Redirect from="*" to="/" />
      </Switch>
    </>
  </Router>
);
