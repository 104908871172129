import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

import { introVideo } from "../../../assets/video";

import "../../../assets/styles/ReactPlayer.css";

const TestComponent = () => {
  const [innerWidth, setInnerWidth] = useState("");
  const [innerHeight, setInnerHeight] = useState("");

  useEffect(() => {
    const _handleResize = () => {
      setInnerWidth(window.innerWidth);
      setInnerHeight(window.innerHeight);
    };
    _handleResize();
    window.addEventListener("resize", _handleResize);
  }, [innerHeight, innerWidth]);

  return (
    <>
      <VideoContainer>
        <ReactPlayer
          className="react-player"
          url={introVideo}
          playing
          muted
          controls
          width={innerWidth}
          height={innerHeight}
          playsinline
        />
      </VideoContainer>
    </>
  );
};

export default TestComponent;

const VideoContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  height: 100vh;
  outline: none;
  background-color: black;
`;
