import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";

import { backBtn } from "../../assets/images";
import { media } from "../../utils/breakPoints";
import Notice from "../../utils/data";

const Main = styled.div`
  height: 100vh;
  background-color: white;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: ${(props) => props.boxShadow};
`;

const VerticalBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const HorizonBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : 0)};
  padding: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const BackBtn = styled.img`
  position: absolute;
  left: 0;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const Title = styled.p`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "15px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  margin-top: ${(props) => `${props.marginTop}px`};
`;

const Bar = styled.div`
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #aaaaaa;
  margin: 0 5px;
  ${media.lessThan("tablet")`
  margin: 0 10px;
  `}
`;

const Link = styled.a`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "15px")};
  color: ${(props) => (props.color ? props.color : "black")};
  cursor: pointer;
  ${media.greaterThan("tablet")`
    .breakpoint{
      display:none;
      line-height:30px;
    }
  `}
`;

const Container = styled.div`
  white-space: pre-wrap;
  padding: 130px 150px 10px;
  background-color: white;
  color: #4a4a4a;
  ${media.between("tablet", "desktop")`
    padding: 130px 100px;    
  `}
  ${media.lessThan("tablet")`
    padding: 130px 50px;  
  `}
`;

const InformationPresenter = ({ match }) => {
  const {
    params: { kinds },
  } = match;

  // console.log("notice", { Notice });

  const [title, setTitle] = useState();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (kinds === "notice") {
      setTitle("공지사항");
    } else if (kinds === "faq") {
      setTitle("자주 묻는 질문");
    }
  }, [kinds]);

  const handleScroll = () => {
    if (window.scrollY < 5) {
      return setIsScrolled(false);
    } else {
      return setIsScrolled(true);
    }
  };

  window.addEventListener("scroll", handleScroll);

  return (
    <>
      <Helmet>
        <title>
          미아펫 : {kinds === "notice" ? "공지사항" : "자주 묻는 질문"}
        </title>
      </Helmet>
      <Main>
        <Header
          boxShadow={isScrolled ? "0px 5px 5px 1px rgba(0, 0, 0, 0.1)" : ""}
        >
          <VerticalBox>
            <HorizonBox>
              <BackBtn
                onClick={() => (window.location.href = "/")}
                src={backBtn}
              />
              <Title fontSize={20} fontWeight={300}>
                {title}
              </Title>
            </HorizonBox>
            <HorizonBox marginTop={10}>
              <Link
                href="/information/notice"
                fontSize={14}
                color={kinds === "notice" ? "#608cf1" : "#aaaaaa"}
              >
                공지사항
              </Link>
              <Bar />
              <Link
                href="/information/faq"
                fontSize={14}
                color={kinds === "faq" ? "#608cf1" : "#aaaaaa"}
              >
                자주 묻는 질문
              </Link>
            </HorizonBox>
          </VerticalBox>
        </Header>
        <Container>
          {kinds === "notice" ? (
            <>
              {Notice.map((item, index) => {
                return (
                  <div key={index}>
                    <div>
                      <h2>{item.title}</h2>
                      <p>{item.subTitle}</p>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div>준비중</div>
          )}
        </Container>
      </Main>
    </>
  );
};

export default InformationPresenter;
