import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 999;
  width: 40px;
`;

const Dot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #333333;
  margin: 5px;
`;

const DotScale = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #51cbfa;
  border: 2px solid #333333;
  margin: 3px;
`;

const Pager = ({ currentPage }) => {
  return (
    <Container>
      {currentPage === 0 ? <DotScale /> : <Dot />}
      {currentPage === 1 ? <DotScale /> : <Dot />}
      {currentPage === 2 ? <DotScale /> : <Dot />}
      {currentPage === 3 ? <DotScale /> : <Dot />}
      {currentPage === 4 ? <DotScale /> : <Dot />}
      {currentPage === 5 ? <DotScale /> : <Dot />}
      {currentPage === 6 ? <DotScale /> : <Dot />}
    </Container>
  );
};

export default Pager;
