import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { media } from "../../../utils/breakPoints";
import gsap from "gsap";

import { con2_ani, con2_neon } from "../../../assets/images";

const SecondComponent = ({ currentPage, widthLimit, widthLong }) => {
  let neon = useRef(null);

  useEffect(() => {
    const neonImg = neon.children[0];

    if (currentPage === 1) {
      gsap.to(neon, 0, { css: { visibility: "visible" } });
      gsap.from(neonImg, 0.6, {
        width: 0,
        height: "100%",
        ease: "power4.out",
      });
    } else if (currentPage !== 1) {
      gsap.to(neon, 0, { css: { visibility: "hidden" } });
    }
  }, [currentPage, widthLong, widthLimit]);

  return (
    <>
      {widthLong && widthLimit ? (
        <HorizonBoxWidth>
          <TextContainerWidth>
            <TitleContainerWidth>
              <NeonImageBoxWidth ref={(el) => (neon = el)}>
                <NeonImage src={con2_neon} alt="형광마크" />
              </NeonImageBoxWidth>
              <TitleWidth>미아펫</TitleWidth>
            </TitleContainerWidth>
            <SubTitleWidth>
              대한민국의 유기동물을
              <br />
              없애고 따뜻한 캠페인을 통해
              <br />
              모든 반려동물과 반려인이 행복한 세상!
              <br />
              함께 살아가는 "반려"의 의미를 찾아갑니다
            </SubTitleWidth>
          </TextContainerWidth>
          <ImageContainerWidth>
            <GifBoxWidth>
              <Image src={con2_ani} alt="gif이미지" />
            </GifBoxWidth>
          </ImageContainerWidth>
        </HorizonBoxWidth>
      ) : (
        <HorizonBox>
          <TextContainer>
            <TitleContainer>
              <NeonImageBox ref={(el) => (neon = el)}>
                <NeonImage src={con2_neon} alt="형광마크" />
              </NeonImageBox>
              <Title>미아펫</Title>
            </TitleContainer>
            <SubTitle>
              대한민국의 유기동물을
              <br />
              없애고 따뜻한 캠페인을 통해
              <br />
              모든 반려동물과 반려인이 행복한 세상!
              <br />
              함께 살아가는 "반려"의 의미를 찾아갑니다
            </SubTitle>
          </TextContainer>
          <ImageContainer>
            <GifBox>
              <Image src={con2_ani} alt="gif이미지" />
            </GifBox>
          </ImageContainer>
        </HorizonBox>
      )}
    </>
  );
};

export default SecondComponent;

const HorizonBox = styled.div`
  display: flex;
  flex-direction: row;
  background: #f8f4ef;
  height: 100vh;
  position: relative;
  ${media.lessThan("desktop")`
    flex-direction:column;
  `}
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${media.greaterThan("desktop")`
    width: 50%; 
  `}
  ${media.lessThan("desktop")`
    position: absolute;  
    width:100vw; 
    height:50%;
    bottom:0;
    justify-content: flex-start;
  `}
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  ${media.greaterThan("desktop")`
    width: 50%;
    padding-left:3%;
  `}
  ${media.lessThan("desktop")`
    position:absolute;
    width:100vw;
    height:50%;
  `}
`;

const TitleContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  ${media.lessThan("desktop")`
    justify-content: center;
  `}
`;

const NeonImageBox = styled.div`
  overflow: hidden;
  z-index: 1;
  visibility:hidden;
  ${media.greaterThan("desktop")`
    width:230px;
    height:110px;
  `}
  ${media.between("tablet", "desktop")`
    width:180px;
    height:100px;
  `}
  ${media.between("mobile", "tablet")`
    width:155px;
    height:80px;
  `}
  ${media.lessThan("mobile")`
    width:125px;
    height:60px;
  `}
`;

const NeonImage = styled.img`
  width: 100%;
  vertical-align: middle;
`;

const Title = styled.p`
  position: absolute;
  color: #333333;
  font-weight: 700;
  z-index: 2;
  text-align:center;
  ${media.greaterThan("desktop")`
    width:200px;
    font-size: 4rem;
    top:40%;
    right:2%;
    transform:translate(0,-50%);
  `}
  ${media.between("tablet", "desktop")`
    width:180px;
    font-size: 3.2rem;
    top:40%;
    left:50%;
    transform:translate(-50%,-50%);
  `}
  ${media.between("mobile", "tablet")`
    width:155px;
    font-size: 2.6rem;
    top:40%;
    left:50%;
    transform:translate(-50%,-50%);
  `}
  ${media.lessThan("mobile")`
    width:125px;
    font-size: 2.2rem;
    top:40%;
    left:50%;
    transform:translate(-50%,-50%);
  `}
`;

const SubTitle = styled.p`
  color: #666666;
  font-size: 2.0rem;
  margin-top: 30px;
  line-height: 45px;
  text-align:right;
  ${media.greaterThan("tablet")`
  padding-right:30px;
    .breakpoint{
      display:none;
    }
  `}
  ${media.between("tablet", "desktop")`
    font-size: 1.6rem;
    text-align:center;
  `}
  ${media.between("mobile", "tablet")`
    font-size: 1.3rem;
    text-align:center;
    line-height: 32px;
  `}
  ${media.lessThan("mobile")`
    font-size: 1rem;
    text-align:center;
    line-height: 28px;
  `}
`;

const GifBox = styled.div`
  overflow: hidden;
  ${media.greaterThan("desktop")`
    width: 700px;
  `}
  ${media.between("tablet", "desktop")`
    margin:0 auto;
    width: 400px;
  `}
  ${media.between("mobile", "tablet")`
    margin:0 auto;
    width: 350px;
  `}
  ${media.lessThan("mobile")`
    margin:0 auto;
    width: 250px;
  `}
`;

const Image = styled.img`
  width: 100%;
`;

//가로모드 css
const HorizonBoxWidth = styled.div`
  display: flex;
  flex-direction: row;
  background: #f8f4ef;
  height: 100vh;
  position: relative;
`;

const ImageContainerWidth = styled.div`
  position: absolute;
  right: 0;
  top: 15%;
  width: 50%;
  padding-left: 3%;
`;

const TextContainerWidth = styled.div`
  position: absolute;
  left: 0;
  top: 25%;
  width: 50%;
`;

const TitleContainerWidth = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  right: -4%;
`;

const TitleWidth = styled.p`
  position: absolute;
  color: #333333;
  font-size: 2.2rem;
  font-weight: 700;
  z-index: 2;
  text-align: center;
  width: 150px;
  top: 40%;
  right: -2%;
  transform: translate(0, -50%);
`;

const SubTitleWidth = styled.p`
  color: #666666;
  font-size: 1rem;
  margin: 10px 0;
  line-height: 25px;
  text-align: right;
`;

const NeonImageBoxWidth = styled.div`
  overflow: hidden;
  z-index: 1;
  width: 130px;
  height: 70px;
  visibility: hidden;
`;

const GifBoxWidth = styled.div`
  overflow: hidden;
  width: 300px;
`;
