import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { media } from "../../../utils/breakPoints";
import gsap from "gsap";

import { con6_ani, con6_neon } from "../../../assets/images";

const SixthComponent = ({ currentPage, widthLimit, widthLong }) => {
  let neon = useRef(null);

  useEffect(() => {
    const neonImg = neon.children[0];

    if (currentPage === 5) {
      gsap.to(neon, 0, { css: { visibility: "visible" } });
      gsap.from(neonImg, 0.6, {
        width: 0,
        height: "100%",
        ease: "power4.out",
      });
    } else if (currentPage !== 5) {
      gsap.to(neon, 0, { css: { visibility: "hidden" } });
    }
  }, [currentPage, widthLong, widthLimit]);

  return (
    <>
      {widthLong && widthLimit ? (
        <HorizonBoxWidth>
          <TextContainerWidth>
            <TitleContainerWidth>
              <NeonImageBoxWidth ref={(el) => (neon = el)}>
                <NeonImage src={con6_neon} alt="형광마크" />
              </NeonImageBoxWidth>
              <TitleWidth>목걸이형 인식표 X</TitleWidth>
            </TitleContainerWidth>
            <SubTitleWidth>
              목걸이형 인식표는 휴대 및 관리가
              <br />
              불편하고 분실 우려가 높습니다 <br />
              미아펫의 비문 기술이라면 <br />
              반려가족의 불편함을 해결할 수 있습니다
            </SubTitleWidth>
          </TextContainerWidth>
          <ImageContainerWidth>
            <GifBoxWidth>
              <Image src={con6_ani} alt="gif이미지" />
            </GifBoxWidth>
          </ImageContainerWidth>
        </HorizonBoxWidth>
      ) : (
        <HorizonBox>
          <TextContainer>
            <TitleContainer>
              <NeonImageBox ref={(el) => (neon = el)}>
                <NeonImage src={con6_neon} alt="형광마크" />
              </NeonImageBox>
              <Title>목걸이형 인식표 X</Title>
            </TitleContainer>
            <SubTitle>
              목걸이형 인식표는 휴대 및 관리가
              <br />
              불편하고 분실 우려가 높습니다 <br />
              미아펫의 비문 기술이라면 <br />
              반려가족의 불편함을 해결할 수 있습니다
            </SubTitle>
          </TextContainer>
          <ImageContainer>
            <GifBox>
              <Image src={con6_ani} alt="gif이미지" />
            </GifBox>
          </ImageContainer>
        </HorizonBox>
      )}
    </>
  );
};

export default SixthComponent;

const HorizonBox = styled.div`
  display: flex;
  flex-direction: row;
  background: #f8f4ef;
  height: 100vh;
  position: relative;
  ${media.lessThan("desktop")`
    flex-direction:column;
  `}
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${media.greaterThan("desktop")`
    width: 50%; 
  `}
  ${media.lessThan("desktop")`
    position: absolute;  
    width:100vw; 
    height:50%;
    bottom:0;
    justify-content: flex-start;
  `}
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  ${media.greaterThan("desktop")`
    width: 50%;
    padding-left:3%;
  `}
  ${media.lessThan("desktop")`
    width:100vw;
    height:50%;
    position: absolute;  
  `}
`;

const TitleContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  ${media.lessThan("desktop")`
    justify-content: center;
  `}
`;

const NeonImageBox = styled.div`
  visibility:hidden;
  overflow: hidden;
  z-index: 1;
  ${media.greaterThan("desktop")`
    width:540px;
    height:130px;
  `}
  ${media.between("tablet", "desktop")`
    width:430px;
    height:100px;
  `}
  ${media.between("mobile", "tablet")`
    width:350px;
    height:80px;
  `}
  ${media.lessThan("mobile")`
    width:300px;
    height:70px;
  `}
`;

const NeonImage = styled.img`
  width: 100%;
  vertical-align: middle;
`;

const Title = styled.p`
  position: absolute;
  color: #333333;
  font-weight: 700;
  z-index: 2;
  text-align:center;
  ${media.greaterThan("desktop")`
    width:500px;
    font-size: 4rem;
    top:40%;
    right:2%;
    transform:translate(0,-50%);
  `}
  ${media.between("tablet", "desktop")`
    width:410px;
    font-size: 3.2rem;
    top:40%;
    left:50%;
    transform:translate(-50%,-50%);
  `}
  ${media.between("mobile", "tablet")`
    width:330px;
    font-size: 2.6rem;
    top:40%;
    left:50%;
    transform:translate(-50%,-50%);
  `}
  ${media.lessThan("mobile")`
    width:280px;
    font-size: 2.2rem;
    top:40%;
    left:50%;
    transform:translate(-50%,-50%);
  `}
`;

const SubTitle = styled.p`
  color: #666666;
  font-size: 2rem;
  margin-top: 30px;
  line-height: 45px;
  text-align:right;
  ${media.greaterThan("tablet")`
  padding-right:30px;
    .breakpoint{
      display:none;
    }
  `}
  ${media.between("tablet", "desktop")`
    font-size: 1.6rem;
    text-align:center;
  `}
  ${media.between("mobile", "tablet")`
    font-size: 1.3rem;
    text-align:center;
    line-height: 32px;
  `}
  ${media.lessThan("mobile")`
    font-size: 1rem;
    text-align:center;
    line-height: 28px;
  `}
`;

const GifBox = styled.div`
  overflow: hidden;
  ${media.greaterThan("desktop")`
    width: 800px;
  `}
  ${media.between("tablet", "desktop")`
    margin:0 auto;
    width: 450px;
  `}
  ${media.between("mobile", "tablet")`
    margin:0 auto;
    width: 380px;
  `}
  ${media.lessThan("mobile")`
    margin:0 auto;
    width: 280px;
  `}
`;

const Image = styled.img`
  width: 100%;
`;

//가로모드 css
const HorizonBoxWidth = styled.div`
  display: flex;
  flex-direction: row;
  background: #f8f4ef;
  height: 100vh;
  position: relative;
`;

const ImageContainerWidth = styled.div`
  position: absolute;
  right: 0;
  top: 10%;
  width: 50%;
  padding-left: 3%;
`;

const TextContainerWidth = styled.div`
  position: absolute;
  left: 0;
  top: 25%;
  width: 50%;
`;

const TitleContainerWidth = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  right: -4%;
`;

const TitleWidth = styled.p`
  position: absolute;
  color: #333333;
  font-size: 2.1rem;
  font-weight: 700;
  z-index: 2;
  text-align: center;
  width: 280px;
  top: 40%;
  right: 2%;
  transform: translate(0, -50%);
`;

const SubTitleWidth = styled.p`
  color: #666666;
  font-size: 1rem;
  margin-top: 5px;
  line-height: 25px;
  text-align: right;
`;

const NeonImageBoxWidth = styled.div`
  visibility: hidden;
  overflow: hidden;
  z-index: 1;
  width: 300px;
  height: 70px;
`;

const GifBoxWidth = styled.div`
  overflow: hidden;
  width: 300px;
`;
