import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from '../utils/breakPoints';
import useResizeHandler from '../hooks/useResizeHandler';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 1000px;
  background: #f8f4ef;
  padding: 50px 0 100px 0;
  ${media.lessThan('desktop')`
    padding: 50px 0 50px 0;
    height: 1700px;
  `}
`;

const VerticalBox = styled.div`
  margin: 0 auto;
  ${media.greaterThan('desktop')`
  width: 1300px;
  `}
  ${media.between('tablet', 'desktop')`
  width: 600px;
  `}
  ${media.between('mobile', 'tablet')`
    width:320px;
  `}
  ${media.lessThan('mobile')`
    width:320px;
  `}
`;

const GridBox = styled.div`
  display: grid;
  ${media.greaterThan('desktop')`
    grid-template-columns: repeat(4, minmax(300px, 1fr));
  `}
  ${media.between('tablet', 'desktop')`
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  `}
  ${media.between('mobile', 'tablet')`
    grid-template-columns: repeat(1, minmax(300px, 1fr));
  `}
  ${media.lessThan('mobile')`
    grid-template-columns: repeat(1, minmax(250px, 1fr));
  `}
`;

const MenuContainer = styled.div`
  ${media.greaterThan('desktop')`
    height: 200px;
  `}
  ${media.lessThan('desktop')`
    height: 150px;
  `}
`;

const Title = styled.p`
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 15px;
  color: #aaaaaa;
  letter-spacing: 0.1px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};
  cursor: ${(props) => (props.cursor ? props.cursor : 'auto')};
`;

const SubTitle = styled.p`
  font-size: 1rem;
  cursor: ${(props) => (props.cursor ? props.cursor : 'pointer')};
  padding: 3px 0;
  color: #aaaaaa;
  letter-spacing: 0.1px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};
  :hover {
    color: #51cbfa;
  }
  ${media.greaterThan('desktop')`
    .breakpoint{
      display:none;
      line-height:30px;
    }
  `}
  ${media.lessThan('desktop')`
    span{
      display:none;
    }
  `}
`;

const SubTitleText = styled.p`
  font-size: 1rem;
  cursor: auto;
  padding: 3px 0;
  color: #aaaaaa;
  letter-spacing: 0.1px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};
  ${media.greaterThan('desktop')`
    .breakpoint{
      display:none;
      line-height:30px;
    }
  `}
  ${media.lessThan('desktop')`
    span{
      display:none;
    }
  `}
`;

const RowButton = styled.p`
  font-size: 1rem;
  cursor: ${(props) => (props.cursor ? props.cursor : 'pointer')};
  padding: 3px 0;
  color: #aaaaaa;
  letter-spacing: 0.1px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};
  margin-right: 15px;
  :hover {
    color: #51cbfa;
  }
  ${media.greaterThan('tablet')`
    .breakpoint{
      display:none;
    }
  `}
  ${media.lessThan('tablet')`
    font-size: 0.9rem;
  `}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  ${media.lessThan('tablet')`
    flex-direction:column;
  `}
`;

const Accordian = styled.div`
  color: #888888;
  letter-spacing: 0.1px;
  font-size: 16px;
  line-height: 28px;
  opacity: 70%;
`;

const AccordianTitle = styled.div`
  display: flex;
  cursor: pointer;
`;

const AccordianItem = styled.div`
  overflow: hidden;
  transition: all 0.5s ease;
  height: ${(props) => (props.animation ? '60px' : '0')};
`;

const AccordianItemM = styled.div`
  overflow: hidden;
  transition: all 0.5s ease;
  height: ${(props) => (props.animation ? '150px' : '0')};
`;

const Footer = () => {
  const BtnClick = () => {
    alert('준비 중입니다.');
  };
  const Cooperate = () => {
    alert('제휴문의는 02-1833-8604로 연락 주세요.');
  };

  const { platform } = useResizeHandler();
  const [showAccordian, setShowAccordian] = useState(false);
  const toggleAccordian = () => {
    setShowAccordian(!showAccordian);
  };
  return (
    <Container>
      <VerticalBox>
        <GridBox>
          <MenuContainer>
            <Title>안내</Title>
            <SubTitle onClick={BtnClick}>공지사항</SubTitle>
            {/* <SubTitle onClick={() => window.open("/information/notice")}>
              공지사항
            </SubTitle> */}
            <SubTitle onClick={BtnClick}>자주 묻는 질문</SubTitle>
            {/* <SubTitle onClick={() => window.open("/information/faq")}>
              자주 묻는 질문
            </SubTitle> */}
          </MenuContainer>
          <MenuContainer>
            <Title>팀</Title>
            <SubTitle
              onClick={() => window.open('https://blog.naver.com/miapet')}
            >
              미아펫 블로그
            </SubTitle>
            {/* <SubTitle onClick={BtnClick}>언론 / 미디어 채용</SubTitle> */}
          </MenuContainer>
          <MenuContainer>
            <Title>기타문의</Title>
            <SubTitle onClick={Cooperate}>비지니스 / 제휴</SubTitle>
            <SubTitle onClick={Cooperate}>마케팅 / 제휴</SubTitle>
          </MenuContainer>
          <MenuContainer>
            <Title>고객센터</Title>
            <SubTitleText>전화 : 02-1833-8604</SubTitleText>
            <SubTitleText>점심시간 : 13:00 ~ 14:00</SubTitleText>
            <SubTitleText>이메일 : miapet.kr@gmail.com</SubTitleText>
          </MenuContainer>
        </GridBox>
      </VerticalBox>
      <VerticalBox>
        <MenuContainer>
          <Title>미아펫</Title>
          <Accordian>
            <AccordianTitle onClick={toggleAccordian}>
              {showAccordian
                ? '로디언즈 (주) 사업자 정보 △'
                : '로디언즈 (주) 사업자 정보 ▽'}
            </AccordianTitle>
            {platform === 'desktop' ? (
              <AccordianItem animation={showAccordian}>
                대표이사 윤창기 <span>|</span> 서울시 서초구 방배동 851-17
                1층~3층 <span>|</span> 사업자등록번호 896-87-00606{' '}
                <span>|</span> 통신판매업 2021-서울서초-1788
                <br />
                ⓒLAWDIANS All rights reserved.
              </AccordianItem>
            ) : (
              <AccordianItemM animation={showAccordian}>
                대표이사 윤창기 <br />
                서울시 서초구 방배동 851-17 1층~3층 <br />
                사업자등록번호 896-87-00606
                <br />
                통신판매업 2021-서울서초-1788
                <br />
                ⓒLAWDIANS All rights reserved.
              </AccordianItemM>
            )}
          </Accordian>
          {/* <SubTitleText marginTop={'10px'}>
            ⓒLAWDIANS All rights reserved.
          </SubTitleText> */}
          <Row>
            <a href="/policy/agreement">
              <RowButton marginTop={'10px'}>서비스 이용약관</RowButton>
            </a>
            <a href="/policy/personal-info">
              <RowButton marginTop={'10px'}>개인정보 취급방침</RowButton>
            </a>
            <a href="/policy/location">
              <RowButton marginTop={'10px'}>위치정보 이용약관</RowButton>
            </a>
            {/* <a href="/policy/push">
              <RowButton marginTop={"10px"}>서비스 알림동의</RowButton>
            </a>
            <a href="/policy/credit">
              <RowButton marginTop={"10px"}>신용카드 간편결제</RowButton>
            </a> */}
          </Row>
        </MenuContainer>
      </VerticalBox>
    </Container>
  );
};

export default Footer;
