import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { media } from "../../../utils/breakPoints";
import gsap from "gsap";

import { con3_ani, con3_neon } from "../../../assets/images";

const ThirdComponent = ({ currentPage, widthLong, widthLimit }) => {
  let neon = useRef(null);

  useEffect(() => {
    const neonImg = neon.children[0];

    if (currentPage === 2) {
      gsap.to(neon, 0, { css: { visibility: "visible" } });
      gsap.from(neonImg, 0.6, {
        width: 0,
        height: "100%",
        ease: "power4.out",
      });
    } else if (currentPage !== 2) {
      gsap.to(neon, 0, { css: { visibility: "hidden" } });
    }
  }, [currentPage, widthLong, widthLimit]);

  return (
    <>
      {widthLong && widthLimit ? (
        <HorizonBoxWidth>
          <ImageContainerWidth>
            <GifBoxWidth>
              <Image src={con3_ani} alt="gif이미지" />
            </GifBoxWidth>
          </ImageContainerWidth>
          <TextContainerWidth>
            <TitleContainerWidth>
              <NeonImageBoxWidth ref={(el) => (neon = el)}>
                <NeonImage src={con3_neon} alt="형광마크" />
              </NeonImageBoxWidth>
              <TitleWidth>비문 식별 O</TitleWidth>
            </TitleContainerWidth>
            <SubTitleWidth>
              사람마다 고유한 지문이 있듯이
              <br />
              반려동물의 코에도 고유한 비문이 있습니다
              <br />
              비문을 조회하면 우리 아이를
              <br />
              고유한 객체로 식별이 가능해집니다
            </SubTitleWidth>
          </TextContainerWidth>
        </HorizonBoxWidth>
      ) : (
        <HorizonBox>
          <ImageContainer>
            <GifBox>
              <Image src={con3_ani} alt="gif이미지" />
            </GifBox>
          </ImageContainer>
          <TextContainer>
            <TitleContainer>
              <NeonImageBox ref={(el) => (neon = el)}>
                <NeonImage src={con3_neon} alt="형광마크" />
              </NeonImageBox>
              <Title>비문 식별 O</Title>
            </TitleContainer>
            <SubTitle>
              사람마다 고유한 지문이 있듯이
              <br />
              반려동물의 코에도 고유한 비문이 있습니다
              <br />
              비문을 조회하면 우리 아이를
              <br />
              고유한 객체로 식별이 가능해집니다
            </SubTitle>
          </TextContainer>
        </HorizonBox>
      )}
    </>
  );
};

export default ThirdComponent;

const HorizonBox = styled.div`
  display: flex;
  flex-direction: row;
  background: #f8f4ef;
  height: 100vh;
  position: relative;
  ${media.lessThan("desktop")`
    flex-direction:column;
  `}
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${media.greaterThan("desktop")`
    width: 50%; 
  `}
  ${media.lessThan("desktop")`
    position:absolute;
    justify-content: flex-start;
    width:100vw;
    height:50%;
    bottom:0;
  `}
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${media.greaterThan("desktop")`
    width: 50%;
    padding-right:3%;
  `}
  ${media.lessThan("desktop")`
    width:100vw;
    height:50%;
    position:absolute;
    
  `}
`;

const TitleContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
  ${media.greaterThan("desktop")`
    left: -4%;
  `}
  ${media.lessThan("desktop")`
    justify-content: center;
  `}
`;

const NeonImageBox = styled.div`
  visibility:hidden;
  overflow: hidden;
  z-index: 1;
  ${media.greaterThan("desktop")`
    width: 380px;
    height:130px;
  `}
  ${media.between("tablet", "desktop")`
    width: 320px;
    height:110px;
  `}
  ${media.between("mobile", "tablet")`
    width: 260px;
    height:90px;
  `}
  ${media.lessThan("mobile")`
    width: 200px;
    height:70px;
  `}
`;

const NeonImage = styled.img`
  width: 100%;
  vertical-align: middle;
`;

const Title = styled.p`
  position: absolute;
  color: #333333;
  font-weight: 700;
  z-index: 2;
  text-align:center;
  ${media.greaterThan("desktop")`
    width:370px;
    font-size: 4rem;
    top:40%;
    left:2%;
    transform:translate(0,-50%);
  `}
  ${media.between("tablet", "desktop")`
    width:300px;
    font-size: 3.2rem;
    top:40%;
    left:50%;
    transform:translate(-50%,-50%);
  `}
  ${media.between("mobile", "tablet")`
    width:240px;
    font-size: 2.6rem;
    top:40%;
    left:50%;
    transform:translate(-50%,-50%);
  `}
  ${media.lessThan("mobile")`
    width:180px;
    font-size: 2.2rem;
    top:40%;
    left:50%;
    transform:translate(-50%,-50%);
  `}
`;

const SubTitle = styled.p`
  color: #666666;
  font-size: 2rem;
  margin-top: 30px;
  line-height: 45px;
  ${media.greaterThan("tablet")`
    .breakpoint{
      display:none;
    }
  `}
  ${media.between("tablet", "desktop")`
    font-size: 1.6rem;
    text-align:center;
  `}
  ${media.between("mobile", "tablet")`
    font-size: 1.3rem;
    text-align:center;
    line-height: 32px;
  `}
  ${media.lessThan("mobile")`
    font-size: 1rem;
    text-align:center;
    line-height: 28px;
  `}
`;

const GifBox = styled.div`
  overflow: hidden;
  ${media.greaterThan("desktop")`
    width: 700px;
  `}
  ${media.between("tablet", "desktop")`
    margin:0 auto;
    width: 400px;
  `}
  ${media.between("mobile", "tablet")`
    margin:0 auto;
    width: 350px;
  `}
  ${media.lessThan("mobile")`
    margin:0 auto;
    width: 250px;
  `}
`;

const Image = styled.img`
  width: 100%;
  transform: rotate(0deg);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`;

//가로모드 css
const HorizonBoxWidth = styled.div`
  display: flex;
  flex-direction: row;
  background: #f8f4ef;
  height: 100vh;
  position: relative;
`;

const ImageContainerWidth = styled.div`
  position: absolute;
  left: 10%;
  top: 10%;
  width: 50%;
  padding-right: 3%;
`;

const TextContainerWidth = styled.div`
  position: absolute;
  right: 0;
  top: 25%;
  width: 50%;
`;

const TitleContainerWidth = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
  left: -3%;
`;

const TitleWidth = styled.p`
  position: absolute;
  color: #333333;
  font-size: 2.2rem;
  font-weight: 700;
  z-index: 2;
  text-align: center;
  width: 190px;
  top: 40%;
  left: 2%;
  transform: translate(0, -50%);
`;

const SubTitleWidth = styled.p`
  color: #666666;
  font-size: 1rem;
  margin: 10px 0;
  line-height: 25px;
`;

const NeonImageBoxWidth = styled.div`
  overflow: hidden;
  z-index: 1;
  width: 210px;
  height: 70px;
  visibility: hidden;
`;

const GifBoxWidth = styled.div`
  overflow: hidden;
  width: 300px;
`;
