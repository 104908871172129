const agreement = `서비스 이용 약관
"미아펫 앱" 서비스 이용약관

이용약관

제1조 [목적]

이 약관은 주식회사 로디언즈 [미아펫 앱] (이하 "회사"라 한다)가 제공하는 반려동물 등록(비문), 관리 서비스(이하 "서비스"라고 한다)의 이용과 관련하여 회사와 회원간의 권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.

제2조 [정의]

이 약관에서 사용하는 용어의 정의는 다음과 같습니다.

① "서비스"라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 "회원"이 이용할 수 있는 반려동물 등록, 관리와 관련한 "미아펫" 제반 서비스를 말합니다.

② "회원"이라 함은 "회사"의 "서비스"에 접속하여 이 약관에 따라 "회사"와 이용계약을 체결하고 "회사"가 제공하는 "서비스"를 이용하는 고객을 말합니다.

③ "아이디(ID)"라 함은 "회원"의 식별과 "서비스" 이용을 위하여 "회원"이 정하고 "회사"가 승인하는 문자와 숫자의 조합을 말합니다.

④ "비밀번호"라 함은 "회원"이 부여 받은 "아이디와 일치되는 "회원"임을 확인하고 비밀보호를 위해 "회원" 자신이 정한 문자 또는 숫자의 조합을 말합니다.

⑤ "비문(鼻紋)"이은 반려동물의 코에 있는 주름의 형태를 말합니다.

⑥ "등록정보"라 함은 반려동물의 품종, 이름, 성별, 사진, 출생일 등의 정보를 말합니다.

⑦ "게시물"이라 함은 "회원"이 "서비스"를 이용함에 있어 "서비스"상에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.

제3조 [약관의 게시와 개정]

① "회사"는 이 약관을 "서비스"의 화면에 온라인으로 게시하거나 기타 유사한 방법으로 "회원" 및 이용자에게 고지하고 동의를 받으면 효력이 발생합니다.

② "회사"는 "약관의 규제에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률", "전자상거래 등에서의 소비자보호에 관한 법률" 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.

③ 개정된 약관에 "회원"의 권리 또는 의무 등 중요한 규정의 개정이 있는 경우 사전에 공지합니다. 다만, "회원"에게 불리한 약관의 개정의 경우에는 전자우편, 전자쪽지, 로그인시 동의창 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.

④ "회사"가 전항에 따라 개정약관을 공지 또는 통지하면서 "회원"에게 7일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 "회원"이 명시적으로 거부의 의사표시를 하지 아니한 경우 "회원"이 개정약관에 동의한 것으로 봅니다.

⑤ "회원"은 약관변경에 동의하지 않을 경우 "회원" 탈퇴(해지)를 요청할 수 있습니다. 다만, "회원"이 변경된 약관의 효력 발생일로부터 10일이 경과한 이후에도 본건 서비스를 계속 사용할 경우에는 약관의 변경에 동의한 것으로 간주됩니다.

제4조 [약관의 해석]

이 약관에 명시되지 않은 사항은 약관의 규제에 관한 법률, 위치정보의 보호 및 이용 등에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령과 회사의 위치기반서비스에 관한 이용약관이나 회사가 별도로 정한 지침 등을 따릅니다.

제5조 ["서비스"의 내용]

"회사"가 제공하는 "서비스"의 내용은 다음 각 호와 같습니다.

1. 반려동물 등록(비문) 및 정보제공 : 반려동물의 비문과 관련 정보를 연계 등록하여 개체 인식 및 관리 등에 활용하는 서비스

2. 찾아주세요 : 반려동물의 보호와 유실, 유기되었을 경우 반려 동물을 등록하여 많은 사용자가 공유 할수 있도록 하는 서비스

4. 찾았습니다 : 유실동물 찾았을 경우 그 결과를 공유하는 서비스

5. 전국유기동물보호소 : 전국의 국립,사립 유기동물보호소의 정보(명칭, 위치, 연락처)를 제공하는 서비스

제6조 ["서비스"의 변경, 중단, 유지, 관리]

① "회사"는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 "서비스"를 변경할 수 있습니다.

② "서비스"의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에 해당 서비스 초기화면에 게시하여야 합니다.

③ "회사"는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 "회원"에게 별도의 보상을 하지 않습니다.

④ "회사"는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 "서비스"의 제공을 일시적으로 중단할 수 있습니다. 이 경우 "회사"는 제11조["회원"에 대한 통지]에서 정한 방법으로 "회원"에게 통지합니다. 다만, "회사"가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.

⑤ "회사"는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.

제7조 [회원가입]

① "회원"이 되고자 하는 자는 "회사"가 정한 양식에 따라 회원가입신청을 하고, 약관의 내용에 대하여 동의한다는 의사표시를 함으로써 회원가입신청을 합니다

② "회사"는 가입신청자의 신청에 대하여 "서비스" 이용을 승낙함을 원칙으로 합니다. 다만, "회사"는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.

1. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우

2. 실명이 아니거나 타인의 명의를 이용한 경우

3. 허위의 정보를 기재하거나, "회사"가 제시하는 내용을 기재하지 않은 경우

4. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우

③ 제1항에 따른 신청에 있어 "회사"는 전문기관을 통한 "회원"의 실명확인 및 본인인증을 요청할 수 있습니다.

④ "회사"는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.

⑤ 제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, "회사"는 원칙적으로 이를 가입신청자에게 알리도록 합니다.

⑥ 이용계약의 성립 시기는 "회사"가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.

제8조 [미성년자의 회원가입에 관한 특칙]

① 만 14세 미만의 "가입신청자"는 개인정보의 수집 및 이용목적에 대하여 충분히 숙지하고 부모 등 법정대리인의 동의를 얻은 후에 회원가입을 신청하고 본인의 개인정보를 제공하여야 합니다.

② "회사"는 부모 등 법정대리인의 동의에 대한 확인절차를 거치지 않은 14세 미만 "가입신청자"에 대하여는 가입을 취소 또는 불허합니다.

③ 만 14세 미만 "가입신청자"의 부모 등 법정대리인은 아동에 대한 개인정보의 열람, 정정, 갱신을 요청하거나 회원가입에 대한 동의를 철회할 수 있으며, 이러한 경우에 "회사"는 지체 없이 필요한 조치를 취해야 합니다.

제9조 [회원정보의 변경]

① "회원"은 개인정보관리화면을 통하여 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 아이디 등은 수정이 불가능합니다.

② "회원"은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 "회사"에 대하여 그 변경사항을 알려야 합니다.

③ 제2항의 변경사항을 "회사"에 알리지 않아 발생한 불이익에 대하여 "회사"는 책임지지 않습니다.

제10조 ["회원"의 "아이디" 및 "비밀번호"의 관리에 대한 의무]

① "회원"의 "아이디"와 "비밀번호"에 관한 관리책임은 "회원"에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.

② "회사"는 "회원"의 "아이디"가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 "회사" 및 "회사"의 운영자로 오인한 우려가 있는 경우, 해당 "아이디"의 이용을 제한할 수 있습니다.

③ "회원"은 "아이디" 및 "비밀번호"가 도용되거나 제3자에 의해 사용되고 있음을 인지한 경우에는 이를 즉시 "회사"에 통지하고 "회사"의 안내에 따라야 합니다.

④ 제3항의 경우에 해당 "회원"이 "회사"에 그 사실을 통지하지 않거나, 통지한 경우에도 "회사"의 안내에 따르지 않아 발생한 불이익에 대하여 "회사"는 책임지지 않습니다.

제11조 ["회원"에 대한 통지]

① "회사"가 "회원"에 대한 통지를 하는 경우 "회원"이 지정한 전자우편주소로 할 수 있습니다.

② "회사"는 "회원" 전체에 대한 통지의 경우 7일 이상 "회사"의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다

제12조 ["서비스" 이용요금]

① 반려동물 비문등록, 정보제공, 유실동물 찾아주세요, 찾았습니다. 는 무료 서비스 입니다.

② 그외 마케팅 및 수익 사업 등 서비스 추가 시 부분 유료화가 될수 있습니다.

제13조 [비문등록, 등록정보]

① "회원"은 "회사"가 제공하는 양식에 따라 반려동물의 정보를 입력하여 비문등록을 합니다.

② 등록된 반려동물의 정보는 "회원"의 요청이 있거나 "회사"가 "서비스" 제공을 위하여 필요시 이용되거나 관련업체에 제공될 수 있습니다.

제14조 [유실동물 신고]

① "회원"은 반려동물의 비문등록 서비스 이용시 유실동물을 "찾아주세요" 를 통해 신고 서비스를 이용할 수 있습니다.

② 유실신고를 받은 "회사"는 홈페이지 등을 통해 유실동물의 정보를 게재하고, 유관기관에 통보하여 "회원"이 유실동물을 찾을 수 있도록 서비스를 제공합니다.

제15조 [상품판매]

① "회원"은 "회사"가 광고, 게재하는 상품에 대하여 구매신청을 하고, 대급을 지급함으로써 청약하고, 이에 대해 "회사"가 승낙하여 구매계약이 성립합니다.

② "회원"은 상품구매에 필요한 정보를 "회사"에 제공하여야 합니다.

③ "회원"의 신청내용에 허위, 기재누락, 오기가 있거나 전산상 오류 등이 있는 경우 "회사"는 승낙을 거부할 수 있습니다.

④ "회사"는 구매계약이 성립한 경우 특별한 사정이 없는 이상 7일 이내에 상품을 배송합니다.

제16조 [청약철회]

① 물품구입을 한 "회원"의 경우, 전자상거래 등에서의 소비자보호에 관한 법률 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 서비스 제공이 늦게 이루어진 경우에는 서비스 제공이 시작된 날)로부터 7일 이내에는 청약의 철회를 할 수 있습니다.

② 제1항의 경우 "회사"는 "회원"에게 이미 지급받은 등록대행 수수료 또는 물품대금을 환급합니다. 다만, 등록대행 수수료의 경우 청약 철회 전 동물등록신청서가 관공서에 이미 접수된 경우에는 반환되지 아니합니다.

제17조 ["회사"의 의무]

① "회사"는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다하여 노력합니다.

② "회사"는 "회원"이 안전하게 "서비스"를 이용할 수 있도록 개인정보보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.

③ "회사"는 서비스이용과 관련하여 발생하는 이용자의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 시스템을 구비합니다.

④ "회사"는 서비스이용과 관련하여 "회원"으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. "회원"이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 "회원"에게 처리과정 및 결과를 전달합니다.

제18조 ["회원"의 의무]

① "회원"은 다음 행위를 하여서는 안 됩니다.

1. 신청 또는 변경 시 허위내용의 등록

2. 타인의 정보도용

3. "회사"가 게시한 정보의 변경

4. "회사"가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시

5. "회사"와 기타 제3자의 저작권 등 지적재산권에 대한 침해

6. "회사" 및 기타 제3자의 명예를 손상시키는 행위

7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 "서비스"에 공개 또는 게시하는 행위

8. 회사의 동의 없이 영리를 목적으로 "서비스"를 사용하는 행위

9. 기타 불법적이거나 부당한 행위

② "회원"은 관계법, 이 약관의 규정, 이용안내 및 "서비스"와 관련하여 공지한 주의사항, "회사"가 통지하는 사항 등을 준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 하여서는 안 됩니다.

제19조 [등록정보의 이용, 제공]

① "회사"는 반려동물의 등록정보를 "서비스" 제공, 개선, 관리를 위하여 이용할 수 있습니다.

② "회사"는 "회원"이 요청한 보험, 동물등록 서비스를 위하여 관련업체에 반려동물의 등록정보를 제공할 수 있습니다.

제20조 [정보의 제공 및 광고의 게재]

① "회사"는 "이용자"가 콘텐츠이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 "회원"에게 제공할 수 있습니다. 다만, "회원"은 언제든지 전자우편 등을 통하여 수신 거절을 할 수 있습니다.

② 제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 "회원"의 사전 동의를 받아서 전송합니다. 다만, 고객문의 등에 대한 회신에 있어서는 제외됩니다.

③ "회사"는 "서비스" 제공과 관련하여 서비스화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편 등을 수신한 "회원"은 수신거절을 "회사"에게 할 수 있습니다.

제21조 [게시물의 저작권]

① "회원"이 "서비스" 내에 "게시물" 대한 책임 및 저작권은 해당 게시물의 저작자에게 있습니다.

② "회사"는 "회원"이 작성한 "게시물"에 대해서 책임지지 않습니다. "회사"는 "회원"이 등록하는 "게시물"의 신뢰성, 진실성, 정확성 등에 대해서 책임지지 않으며 보증하지 않습니다.

③ "서비스"에 대한 저작권 및 지적재산권, "회사"가 작성한 "게시물"의 저작권은 "회사"에 귀속됩니다. 단, "회원"이 단독 또는 공동으로 작성한 "게시물"은 제외합니다.

④ 회원이 서비스 내에 게시하는 게시물은 검색결과 내지 서비스 운영 및 홍보 등을 위해서 미아펫(miapet)이 운영하는 매체(페이스북, 인스타그램, 유튜브, 블로그 등), 홈페이지, 기타의 방법(현재 알려져 있고 향후 개발되는 것을 포함)에서 사용될 수 있습니다. 이때 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우, "회원"은 언제든지 고객센터 또는 서비스 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 요구할 수 있습니다.

제22조 ["게시물"의 관리]

① "회원"의 "게시물"이 "정보통신망법" 및 "저작권법"등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 "게시물"의 게시중단 및 삭제 등을 요청할 수 있으며, "회사"는 관련법에 따라 조치를 취하여야 합니다.

② "회사"는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 "게시물"에 대해 임시조치 등을 취할 수 있습니다.

③본 조에 따른 세부절차는 "정보통신망법" 및 "저작권법"이 규정한 범위 내에서 "회사"가 정한 "게시중단요청서비스"에 따릅니다.

제23조 [저작권 등의 귀속]

① "서비스"에 대한 저작권 및 지적재산권은 "회사"에 귀속됩니다.

② "회사"가 제공하는 "서비스" 중 제휴계약에 의해 제공되는 저작물에 대한 저작권 기타 지적재산권은 해당 제공업체에 귀속합니다.

③ "회원"은 "회사"가 제공하는 서비스를 이용함으로써 얻은 정보 중 "회사" 또는 제공업체에 지적재산권이 귀속된 정보를 "회사" 또는 제공업체의 사전승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.

④ "회사"는 약정에 따라 "회원"의 저작물을 사용하는 경우 당해 "회원"의 허락을 받습니다.

제24조 [탈퇴, 해지]

① "회원"은 언제든지 서비스초기화면의 고객센터 또는 내 정보 관리 메뉴 등을 통하여 이용계약 해지 신청을 할 수 있으며, "회사"는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.

② "회원"이 계약을 해지할 경우, 관련법 및 개인정보처리방침에 따라 "회사"가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 "회원"의 모든 데이터는 소멸됩니다.

③ "회원"이 계약을 해지하는 경우, "회원"이 작성한 "게시물" 일체는 삭제됩니다. 다만, 타인에 의해 담기, 스크랩 등이 되어 재게시되거나, 공용게시판에 등록된 "게시물" 등은 삭제되지 않으니 사전에 삭제 후 탈퇴하시기 바랍니다.

제25조 [이용제한 등]

① "회사"는 "회원"이 이 약관의 의무를 위반하거나 "서비스"의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 "서비스" 이용을 단계적으로 제한할 수 있습니다.

② "회사"는 전항에도 불구하고, "주민등록법"을 위반한 명의도용 및 결제도용, "저작권법" 및 "컴퓨터프로그램보호법"을 위반한 불법프로그램의 제공 및 운영방해, "정보통신망법"을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지 시 "서비스" 이용을 통해 획득한 혜택 등은 모두 소멸되며, "회사"는 이에 대해 별도로 보상하지 않습니다.

③ "회사"는 "회원"이 계속해서 (12개월 이상) 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.

④ "회사"는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 이용제한정책 및 개별 서비스상의 운영정책에서 정하는 바에 의합니다.

⑤ 본 조에 따라 "서비스" 이용을 제한하거나 계약을 해지하는 경우에는 "회사"는 제11조["회원"에 대한 통지]에 따라 통지합니다.

⑥ "회원"은 본 조에 따른 이용제한 등에 대해 "회사"가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 "회사"가 인정하는 경우 "회사"는 즉시 "서비스"의 이용을 재개합니다.

제26조 [면책조항]

① "회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이 면제됩니다.

② "회사"는 "회원"의 귀책사유로 인한 "서비스" 이용의 장애에 대하여는 책임을 지지 않습니다.

③ "회사"는 "회원"이 "서비스"와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.

④ "회사"는 "회원" 간 또는 "회원"과 제3자 상호간에 "서비스"를 매개로 하여 발생한 분쟁, 거래 등을 한 경우에는 책임이 면제됩니다.

⑤ "회사"는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.

제27조 [분쟁의 해결]

① "회사"와 "회원" 간 제기된 소송은 대한민국법을 준거법으로 합니다.

② "서비스" 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 "회사"의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.
`;

const personalInfo = `개인정보취급방침
"미아펫 앱" 개인정보취급방침

주식회사 로디언즈 / 미아펫 앱 (이하 "회사"라 함)는 개인정보 보호법에 따라 회사가 제공하는 인터넷사이트 및 모바일 어플리케이션(이하 "서비스"이라 함)에서 수집, 이용하는 개인정보와 그 처리 기준을 다음과 같이 수립, 공개합니다.

제1조 [개인정보의 처리 목적]

"회사"는 서비스 이용자의 본인 식별, 서비스 제공 및 이용의사 확인 등 최적화되고 맞춤화된 서비스를 제공하기 위하여 개인정보를 수집, 처리하고 있습니다. 회원이 제공한 모든 정보는 상기 목적에 필요한 용도 이외로는 사용되지 않으며, 수집정보의 범위나 사용목적, 용도가 변경될 시에는 반드시 회원에게 사전동의를 구합니다.

제2조 [처리하는 개인정보 항목]

① "회사"는 다음의 개인정보를 처리하고 있습니다.

- 회원가입 및 개인식별 및 부정 이용방지: 성명, 휴대폰번호, 성별, 생년월일, 이메일(ID), 비밀번호, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI)

- 동물등록 시: 반려동물의 품종, 이름, 성별, 사진, 출생일, 입양일, 동물등록번호, 털 색상, 중성화 여부 및 특징

- 비문등록 시: 반려동물의 코 사진(비문)

- 찾아주세요 시: 개인정보 동의시 성명, 휴대폰번호, 반려동물의 동물정보

- 유료서비스 이용 시: 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 신용카드번호, 은행계좌정보 등

- 서비스 이용 및 처리 과정에서 자동으로 생성되어 수집 : IP Address, 쿠키, 방문 일시, 위치정보, 서비스 이용기록, 불량 이용기록

② "회사"는 회원의 주민번호를 원칙적으로 수집 또는 이용하지 않으며, 대체수단(휴대폰 전화 인증 등)을 활용하여 인증을 합니다.

제3조 [개인정보 처리 및 보유기간]

① "회사"는 다음 각 호의 경우에 개인정보를 수집할 수 있으며, 그 수집 목적의 범위에서 이용할 수 있습니다. 

1) 정보주체로부터 사전에 동의를 받은 경우

2) 개인정보를 수집·이용하지 않고는 정보주체와 계약을 체결하고, 체결된 계약의 내용에 따른 의무를 이행하는 것이 불가능하거나 현저히 곤란한 경우

3) 개인정보보호법 등에서 허용하는 경우

② 회원가입시 등록한 회원의 개인정보는 수집목적 또는 제공받은 목적이 달성되거나 "서비스"를 해지시까지 계속 보유합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.

가. 회사 내부 방침에 의한 정보보유 사유

- 부정이용기록

보존 이유: 부정 이용 방지

보존 기간: 1년

- 사기 예방을 위해 필요한 기록

보존 이유: 사기 예방

보존 기간: 3년

나. 관련법령에 의한 정보보유 사유

상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

- 계약 또는 청약철회 등에 관한 기록

보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률

보존 기간: 5년

- 대금결제 및 재화 등의 공급에 관한 기록

보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률

보존 기간: 5년

- 전자금융 거래에 관한 기록

보존 이유: 전자금융거래법

보존 기간: 5년

- 소비자의 불만 또는 분쟁처리에 관한 기록

보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률

보존 기간: 3년

- 본인확인에 관한 기록

보존 이유: 정보통신망 이용촉진 및 정보보호 등에 관한 법률

보존 기간: 6개월

- 방문에 관한 기록

보존 이유: 통신비밀보호법

보존 기간: 3개월

제4조 [개인정보처리의 위탁]

① 회사는 원칙적으로 개인정보를 정보주체가 동의한 범위 내에서 사용하며, 정보주체의 사전 동의 없이는 동 범위를 초과하여 이용하거나 제3자에게 공개하지 않습니다. 

② 제1항에도 불구하고 정보주체가 별도 동의를 한 경우 또는 개인정보보호법에 의한 경우에는 개인정보를 제3자에게 제공할 수 있습니다.

③ 제2항에 따라 개인정보를 제공받는 자는 다음과 같습니다.

- 제공받는자 : 관할 시∙군∙구청 그외 협약 업체

이용목적 : 동물 유실 시 정보 확인 및 마케팅, 캠페인 등

제공대상 정보 : 개인정보취급방침에서 정하는 개인(신용)정보 및 반려동물 정보

제공받는 자의 정보 보유 및 이용기간 : 회원의 서비스 이용기간 및 관할 시∙군∙구청 유실 동물 확인 완료시까지

제5조 [개인정보의 파기절차 및 방법]

"회원"의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.

① 파기절차

- "회원"이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.

- 별도 DB로 옮겨진 개인정보는 법률에 의한 경우를 제외하고는 다른 목적으로 이용되지 않습니다.

② 파기방법

- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.

- 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.

제6조 [개인정보의 처리 위탁]

"회사"는 원활하고 향상된 "서비스"를 위하여 개인정보 취급을 타인에게 위탁할 수 있으며, 관계법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 합니다. 또한 위탁 처리하는 정보는 원활한 서비스를 제공하기 위하여 필요한 최소한의 정보에 국한됩니다.

제7조 [개인정보의 안정성 확보조치]

"회사"는 이용자의 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.

1. 관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등

2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화

(단, 이용자 본인의 부주의나 인터넷상의 문제로 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.)

제8조 [개인정보 주체의 권리, 의무, 행사]

- "회원"은 언제든지 본인의 개인정보를 열람 및 정정 삭제할 수 있습니다. "회원"이 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 본인에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.

- 만 14세 미만 아동의 경우, 법정대리인이 아동의 개인정보를 조회하거나 수정할 권리, 수집 및 이용 동의를 철회할 권리를 가집니다.

- "회원"은 스스로 개인정보를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 비밀번호를 포함한 개인정보가 유출되지 않도록 주의를 필요로 하고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해야 합니다. 만약 이 같은 책임을 다하지 못하고 타인의 정보를 훼손할 시에는『정보통신망 이용촉진 및 정보보호 등에 관한 법률』등에 의해 처벌받을 수 있습니다.

제9조 [정보의 제공 및 광고의 게재]

1. 회사는 고객의 사전 동의 없이 영리 목적의 광고성 정보를 전송하지 않습니다.

2. 신규 컨텐츠 알림이나 이벤트 정보안내 등 고객 지향적인 마케팅을 수행하기 위하여 광고성 정보를 App. PUSH로 전송할 때 회사는 광고성 정보 전송에 대한 고객의 사전 동의를 얻고 App. PUSH의 본문란에 아래와 같이 고객이 광고성 정보임을 쉽게 알아볼 수 있도록 조치합니다.

- App. PUSH 본문란: ‘(광고)’ 라는 문구를 본문 맨 처음에 빈칸 없이 한글로 표시합니다.

다. 광고 수신에 동의한 고객에게 이메일, 문자메시지 전송 등 App. PUSH 이외의 방법으로 영리 목적의 광고성 정보를 전송할 때에도 전송자의 명칭을 표기하는 등 필요한 조치를 합니다.

제10조 [개인정보 관리 책임자]

회사는 정보통신부 지정 개인정보지침에 의거 관리책임자 및 담당부서를 아래와 같이 지정합니다.

개인정보관리책임자

– 성명 : 김창섭 이사

– 전화번호 : 02-1833-8604

– 팩스번호: 0504-400-6294

– 이메일 : miapet.kr@gmail.com

개인정보보호 담당부서

– 부서명 : 미아펫 운영팀

– 성명 : 김창섭 이사

– 전화번호 : 02-1833-8604

– 팩스번호: 0504-400-6294

– 이메일 : miapet.kr@gmail.com

제10조 [권익침해 구제방법]

개인정보 침해신고센터 (한국인터넷진흥원 운영)

- 소관업무 : 개인정보 침해사실 신고, 상담 신청

- 홈페이지 : privacy.kisa.or.kr

- 전화 : (국번없이) 118

- 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터

개인정보 분쟁조정위원회

- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)

- 홈페이지 : www.kopico.go.kr

- 전화 : (국번없이) 1833-6972

- 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층

대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)

경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)

제11조 [개인정보처리방침 변경 및 고지의 의무]

현 개인정보취급방침은 정부의 정책 또는 "회사"의 필요에 따라 변경될 수 있으며, 내용의 추가 삭제 및 수정이 있을 시에는 시행 7일 전 인터넷 홈페이지 및 모바일을 통해 사전 고지할 것입니다. 사전공지가 곤란할 경우 지체없이 공지하며, 이 정책은 공지한 날로부터 시행됩니다.

- 개인정보취급방침 공고일자: 2020년 5월 25일

- 개인정보취급방침 시행일자: 2020년 5월 25일`;

const location = `위치 정보 이용 약관
"미아펫 앱" 위치정보이용약관

제1조 [목적]

본 약관은 주식회사 로디언즈 / "미아펫 앱" (이하 "회사")가 제공하는 위치기반서비스에 대해 회사와 위치기반서비스를 이용하는 개인위치정보주체(이하 "회원")간의 권리·의무 및 책임사항, 기타 필요한 사항 규정을 목적으로 합니다.

제2조 [이용약관의 효력 및 변경]

① 본 약관은 이용자가 본 약관에 동의하고 회사가 정한 절차에 따라 위치기반서비스의 이용자로 등록됨으로써 효력이 발생합니다.

② 회사는 법률이나 위치기반서비스의 변경사항을 반영하기 위한 목적 등으로 약관을 수정할 수 있습니다.

③ 약관이 변경되는 경우 회사는 변경사항을 최소 7일 전에 회사의 홈페이지 등 기타 공지사항 페이지를 통해 게시합니다.

④ 단, 개정되는 내용이 이용자 권리의 중대한 변경이 발생하는 경우에는 30일 전에 게시하도록 하겠습니다.

제3조 [약관 외 준칙]

이 약관에 명시되지 않은 사항에 대해서는 위치 정보의 보호 및 이용 등에 관한 법률, 전기통신사업법, 정보통신망 이용 촉진및 보호 등에 관한 법률 등 관계법령 및 회사가 정한 지침 등의 규정에 따릅니다.

제4조 [서비스의 내용]

회사는 직접 수집하거나 위치정보사업자로부터 수집한 이용자의 현재 위치 또는 현재 위치가 포함된 지역을 이용하여 아래와 같은 위치기반서비스를 제공합니다.

① 위치정보를 활용한 유실견 검색결과 및 유실견 정보 제공하거나 추천

② 유실견 제보를 위한 위치 공유, 위치/지역에 따른 알림, 경로 안내

③ 위치기반의 유실견 분류를 위한 콘텐츠 태깅(Geotagging)

④ 위치기반의 맞춤형 광고

제5조 [서비스 이용요금]

회사가 제공하는 위치기반서비스는 무료입니다.

단, 무선 서비스 이용 시 발생하는 데이터 통신료는 별도이며, 이용자가 가입한 각 이동통신사의 정책에 따릅니다.

제6조 [서비스 이용의 제한·중지]

① 회사는 위치기반서비스사업자의 정책변경 등과 같이 회사의 제반사정 또는 법률상의 이유로 위치기반서비스를 유지할 수 없는 경우 위치기반서비스의 전부 또는 일부를 제한·변경·중지할 수 있습니다.<

② 단, 위 항에 의한 위치기반서비스 중단의 경우 회사는 사전에 회사 홈페이지 등 기타 공지사항 페이지를 통해 공지하거나 이용자에게 통지합니다.

제7조 [개인위치정보주체의 권리]

① 이용자는 언제든지 개인위치정보의 수집·이용·제공에 대한 동의 전부 또는 일부를 유보할 수 있습니다.

② 이용자는 언제든지 개인위치정보의 수집·이용·제공에 대한 동의 전부 또는 일부를 철회할 수 있습니다. 이 경우 회사는 지체 없이 철회된 범위의 개인위치정보 및 위치정보 수집·이용·제공사실 확인자료를 파기합니다.

③ 이용자는 개인위치정보의 수집·이용·제공의 일시적인 중지를 요구할 수 있으며, 이 경우 회사는 이를 거절할 수 없고 이를 충족하는 기술적 수단을 마련합니다.

④ 이용자는 회사에 대하여 아래 자료에 대한 열람 또는 고지를 요구할 수 있으며, 해당 자료에 오류가 있는 경우에는 정정을 요구할 수 있습니다. 이 경우 정당한 사유 없이 요구를 거절하지 않습니다.

1. 이용자에 대한 위치정보 수집·이용·제공사실 확인자료

2. 이용자의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법령의 규정에 의하여 제3자에게 제공된 이유 및 내용

⑤ 이용자는 권리행사를 위해 본 약관 제14조의 연락처를 이용하여 회사에 요청할 수 있습니다.

제8조 [개인위치정보의 이용 또는 제공]

① 회사는 개인위치정보를 이용하여 위치기반서비스를 제공하는 경우 본 약관에 고지하고 동의를 받습니다.

② 회사는 이용자의 동의 없이 개인위치정보를 제3자에게 제공하지 않으며, 제3자에게 제공하는 경우에는 제공받는 자 및 제공목적을 사전에 이용자에게 고지하고 동의를 받습니다.

③ 회사는 개인위치정보를 이용자가 지정하는 제3자에게 제공하는 경우 개인위치정보를 수집한 통신단말장치로 매회 이용자에게 제공받는 자, 제공일시 및 제공목적을 즉시 통지합니다.

④ 단, 아래의 경우 이용자가 미리 특정하여 지정한 통신단말장치 또는 전자우편주소, 온라인게시 등으로 통지합니다.

1. 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지 아니한 경우

2. 이용자의 개인위치정보를 수집한 통신단말장치 외의 통신단말장치 또는 전자우편주소, 온라인게시 등으로 통보할 것을 미리 요청한 경우

⑤ 회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에 근거하여 개인위치정보 수집·이용·제공사실 확인자료를 자동으로 기록·보존하며, 해당 자료는 6개월간 보관합니다.

제9조 [법정대리인의 권리]

회사는 14세 미만의 이용자에 대해서는 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한 동의를 이용자 및 이용자의 법정대리인으로부터 동의를 받아야 합니다. 이 경우 법정대리인은 본 약관 제7조에 의한 이용자의 권리를 모두 가집니다.

제10조 [8세 이하의 아동 동의 보호의무자의 권리]

① 회사는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동 등”)의 위치정보의 보호 및 이용 등에 관한 법률 제26조2항에 해당하는 자(이하 “보호의무자”)가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 봅니다.

1. 8세 이하의 아동

2. 피성년후견인

3. 장애인복지법 제2조제2항제2호에 따른 정신적 장애를 가진 사람으로서 장애인고용촉진 및 직업재활법 제2조제2호에 따른 중증장애인에 해당하는 사람(장애인복지법 제32조에 따라 장애인 등록을 한 사람만 해당한다)

② 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다.

③ 보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에 동의하는 경우 본 약관 제7조에 의한 이용자의 권리를 모두 가집니다.

제11조 [손해배상]

회사의 위치정보의 보호 및 이용 등에 관한 법률 제15조 및 26조의 규정을 위반한 행위로 인해 손해를 입은 경우 이용자는 회사에 손해배상을 청구할 수 있습니다. 회사는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.

제12조 [면책]

① 회사는 다음 각 호의 경우로 위치기반서비스를 제공할 수 없는 경우 이로 인하여 이용자에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.

1. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우

2. 위치기반서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우

3. 이용자의 귀책사유로 위치기반서비스 이용에 장애가 있는 경우

4. 제1호 내지 제3호를 제외한 기타 회사의 고의·과실이 없는 사유로 인한 경우

② 회사는 위치기반서비스 및 위치기반서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 이용자의 손해에 대하여는 책임을 부담하지 아니합니다.

제13조 [분쟁의 조정 및 기타]

① 회사는 위치정보와 관련된 분쟁의 해결을 위해 이용자와 성실히 협의합니다.

② 전항의 협의에서 분쟁이 해결되지 않은 경우, 회사와 이용자는 위치정보의 보호 및 이용 등에 관한 법률 제28조의 규정에 의해 방송통신위원회에 재정을 신청하거나, 개인정보보호법 제43조의 규정에 의해 개인정보 분쟁조정위원회에 조정을 신청할 수 있습니다.

제14조 [회사의 주소 및 연락처]

회사의 상호, 주소 및 연락처는 아래와 같습니다.

상호 : 주식회사 로디언즈 / "미아펫 앱"

대표 : 윤창기

주소 : 서울특별시 동작구 상도로 369

대표전화 : 02-1833-8604`;

const credit = `미아펫앱 서비스를 이용하는 회원은 아래의 내용에 대하여 거부 하실 수 있으며, 거부 시 상품배송, 구매 및 결제, 향후 일부 포인트 적립에 제한됩니다.

1. 수집, 이용목적
   - 대금 결제/환불 서비스 제공, 주문/배송/거래내역조회서비스 제공, 전자상거래법 준수 등

2. 수집 항목
   - 신용카드 정보, 계좌 정보, 주문/배송/거래 내역

3. 보유 기간
   - 회원 탈퇴 시 까지 보유. 단, 관계 법령의 규정에 다라 보존할 의무가 있으면 해당 기간 동안 보존

이용계약(이용약관)이 존속중인 탈퇴를 진행하지 않은 회원의 경우 보유기간은 보존의무기간 이상 보관 할 수 있으며, 이 기간이 경과된 기록에 대해서
파기 요청이 있는 경우 파기함

결제 수단에 따른 개인정보 수집 및 이용 항목이 상이 할 수 있음
 `;

const push = `[서비스 알림(광고성 정보) 수신 동의]

 서비스 정보 및 광고성 정보 알림 수신에 관한 동의는 선택사항으로서, 이용자는 동의를 거부하더라도 회원가입 및 앱 서비스 사용이 가능합니다. 단, 동의를 거부할 경우 각종 이벤트와 혜택, 서비스 이용안내에 제한이 발생할 수 있습니다.
 
 수집/이용 목적: 회사, 제휴브랜드 관련된 각종 유무형의 상품 및 이벤트 정보나 소식, 무료 책자/혜택 소식, 뉴스레터 등 광고성정보의 수신
 수집항목	: 이메일, 휴대전화번호 모바일 앱의 경우 앱Push 수신여부
 보유 및 이용기간	: 동의 철회시점까지 보유
 
 ※ 자세한 내용은 개인정보 수집 및 처리방침 약관을 참고하시기 바랍니다.
 
`;

export { agreement, personalInfo, location, credit, push };
